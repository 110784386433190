import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Page from '../templates/page';

/**
 * This component is used to display preview for an unpublished page. Previews for published pages
 * will go directly to that page, but unpublished pages need a placeholder page (like this) to be
 * able to view them. Our link resolver checks to see if we're previewing an unpublished page and
 * redirects to this page - once here, we just render the page component with the preview query
 * which is mostly the same as the regular page query, just with any references to `Sharp` image
 * fields being removed. Since the Prismic ref for the previewable content is stored in a cookie,
 * the application uses that to retrieve the unpublished content for preview in the query.
 *
 * @param {object} props Component props.
 */
const PagePreview = props => <Page {...props} />;

export const query = graphql`
  query PagePreviewQuery($uid: String) {
    prismic {
      allPages(uid: $uid) {
        edges {
          node {
            title
            content
            featured_image
          }
        }
      }
    }
  }
`;

PagePreview.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default PagePreview;
